<template>
   <b-nav-item-dropdown right class="notification-list" menu-class="dropdown-lg">
      <template slot="button-content" >
        <span class="nav-link  dropdown-toggle">
          <i class="fe-bell noti-icon"></i>
          <span v-if="unreadNotifications.length" class="badge badge-danger rounded-circle noti-icon-badge">{{unreadNotifications.length}}</span>
        </span>
      </template>
      <a href="javascript:void(0);" class="dropdown-item noti-title">
          <h5 class="m-0">
              <span class="float-right">
              </span>
              Notifications
          </h5>
      </a>

      <simplebar style="max-height: 230px">
        <div v-for="(notif, index) in activeNotifs" :key="index">
          <a href="javascript:void(0)"  @click.prevent="navigateTo(notif)" 
          class="dropdown-item notify-item" :class="{'active': !notif.is_seen }">
            <div class="notify-icon bg-soft-primary text-primry">
              <i class="mdi mdi-message-text"></i>
            </div>
            <p class="notify-details">
                <span>{{ notif.title }}</span>
                <small class="text-muted d-block font-10">{{ notif.content | truncate(50)  }}</small>
                <small class="text-muted d-block font-10">{{ notif.created_at | date_ago }}</small>
            </p>
          </a>
        </div>
      </simplebar>

      <a
        href="javascript:void(0);"
        class="dropdown-item text-center text-primary notify-item notify-all"
        @click.prevent="$router.push({path: '/notifications'})">
          View all
          <i class="fi-arrow-right"></i>
      </a>
  </b-nav-item-dropdown>
</template>

<script>

export default {
  data() {
    return {
    }
  },
  computed: {
    pageData() {
      return this.$store.state.notification.pageData
    },
    unreadNotifications() {
      return this.pageData.data.filter((notif) => (notif.is_seen == 0))
    },
    readNotifications() {
      return this.pageData.data.filter((notif) => notif.is_seen == 1 );
    },
    activeNotifs() {
      return this.unreadNotifications.length > 1
        ? [...this.unreadNotifications.slice(0,10), ...this.readNotifications.slice(0,5)]
        : this.pageData.data.slice(0, 10);
    },
    activeUser() {
      return this.$store.state.AppActiveUser
    }
  },
  methods:{
    navigateTo(notif){
      if(!notif.is_seen){
        this.$store.dispatch("notification/markAsRead", notif.id);
      }
      this.$router.push({path: notif.url });
    },
  },
  created(){
    this.$store.dispatch("notification/fetchNotifications")
  },
  mounted(){
    // Echo.channel("teacher-notify-channel-"+this.activeUser.id)
    //   .listen('NotificationEvent', (e) => {
    //     this.$store.commit('notification/ADD_NOTIFICATION',e.data)
    //     if(e.data.action === "fetch-group" && moduleGroupList.isRegistered && e.data.group_id){
    //       let group = this.$store.state.groupList.group;
    //       if(group && group.id == e.data.group_id){
    //         this.$store.dispatch("groupList/fetchGroup", e.data.group_id)
    //       }
    //     }
    // });
  }
}

</script>


<style>
  .notification-list .dropdown-toggle{
      position: relative;
  }
</style>
