
<template>
<!-- Topbar Start -->
<div class="navbar-custom">
    <div class="container-fluid">
        <ul class="list-unstyled topnav-menu float-right mb-0">
            <i18n />
            <notification-menu />
            <profile-drop-down />
        </ul>
        <div class="logo-box">
            <a :href="absoluteUrl()" target="_blank" class="logo logo-dark text-center">
                <span class="logo-sm">
                    <img :src="absoluteUrl('/images/logo/logo-sm.png')" alt height="24" />
                </span>
                <span class="logo-lg">
                    <img :src="absoluteUrl('/images/logo/logo.png')" alt height="35" />
                </span>
            </a>
            <a :href="absoluteUrl()" target="_blank" class="logo logo-light text-center">
                <span class="logo-sm">
                    <img :src="absoluteUrl('/images/logo/logo-sm.png')" alt height="24" />
                </span>
                <span class="logo-lg">
                    <img :src="absoluteUrl('/images/logo/logo.png')" alt height="35" />
                </span>
            </a>
        </div>

        <ul class="list-unstyled topnav-menu topnav-menu-left m-0">
            <li>
                <button class="button-menu-mobile waves-effect waves-light" @click="toggleMenu">
                    <i class="fe-menu"></i>
                </button>
            </li>

            <li>
                <a class="navbar-toggle nav-link" data-toggle="collapse" @click="horizonalmenu()" data-target="#topnav-menu-content">
                    <div class="lines">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </a>
            </li>
        </ul>
        <div class="clearfix"></div>
    </div>
</div>
</template>

<script>
import SearchBar            from "./components/SearchBar.vue"
import ProfileDropDown      from "./components/ProfileDropDown.vue"
import NotificationMenu    from "./components/NotificationMenu.vue"
import I18n                 from "./components/I18n.vue"

export default {
    components: {
        SearchBar,
        ProfileDropDown,
        NotificationMenu,
        I18n,
      },
    data() {
        return {
        }
    },
    computed: {
        activeUser() {
          return this.$store.state.AppActiveUser
        }
    },
    methods: {
        /**
         * Toggle menu
         */
        toggleMenu() {
            this.$parent.toggleMenu();
        },
        /**
         * Full screen
         */
        initFullScreen() {
            document.body.classList.toggle("fullscreen-enable");
            if (
                !document.fullscreenElement &&
                /* alternative standard method */
                !document.mozFullScreenElement &&
                !document.webkitFullscreenElement
            ) {
                // current working methods
                if (document.documentElement.requestFullscreen) {
                    document.documentElement.requestFullscreen();
                } else if (document.documentElement.mozRequestFullScreen) {
                    document.documentElement.mozRequestFullScreen();
                } else if (document.documentElement.webkitRequestFullscreen) {
                    document.documentElement.webkitRequestFullscreen(
                        Element.ALLOW_KEYBOARD_INPUT
                    );
                }
            } else {
                if (document.cancelFullScreen) {
                    document.cancelFullScreen();
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                } else if (document.webkitCancelFullScreen) {
                    document.webkitCancelFullScreen();
                }
            }
        },
        /**
         * Toggle rightbar
         */
        toggleRightSidebar() {
            this.$parent.toggleRightSidebar();
        },

        /**
         * Horizontal-toggle menu
         */
        horizonalmenu() {
            let element = document.getElementById("topnav-menu-content");
            element.classList.toggle("show");
        },


    },
};
</script>

