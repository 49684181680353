export const menuItems = [
    {
        id: 1,
        label: "Menu",
        i18n: "Menu",
        isTitle: true
    },
    {
        id: 2,
        label: "Dashboard",
        i18n: "Dashboard",
        icon: "ri-dashboard-line",
        link: '/dashboard'
    },
    {
        id: 22,
        label: "Languages",
        i18n: "Languages",
        icon: "ri-sort-desc",
        link: '/languages'
    },
    {
        id: 23,
        label: "Account",
        i18n: "account",
        icon: "ri-shield-user-line",
        link: '/account'
    },
    {
        id: 24,
        label: "Notifications",
        i18n: "Notifications",
        icon: "ri-notification-2-line",
        link: '/notifications'
    },
];
