
<template>
<div>
  <div id="wrapper">
    <Sidebar :type="layout.leftSidebarType" :width="layout.layoutWidth" 
    :size="layout.leftSidebarSize" :menu="layout.menuPosition" :topbar="layout.topbar" />
    <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== -->
    <div class="content-page">
        <div class="content">
            <Topbar />
            <!-- Start Content-->
            <div class="container-fluid px-0">
                <page-header v-if="layout.pageHeader" :title="$route.meta.pageTitle" 
                :items="$route.meta.breadcrumb" />
                <div class="content_wrapper">
                    <div class="main_content_wrapper">
                        <transition name="fade" mode="out-in">
                            <router-view />
                        </transition>
                    </div>
                    <div class="ads_content_wrapper">
                        <!-- Edidiosdsadfasdf -->
                    </div>
                </div>
            </div>
        </div>
        <Footer v-if="layout.pageFooter"/>
    </div>
  </div>
</div>
</template>
<script>

import themeConfig    from '@/themeConfig.js'
import Sidebar from '@/components/app/LeftSidebar.vue'
import Topbar from '@/components/app/Topbar.vue'
import PageHeader from '@/components/app/PageHeader.vue'
import Footer from '@/components/app/Footer.vue'

export default {
  components: {
    Sidebar,
    Topbar,
    PageHeader,
    Footer
  },
  data() {
    return {
      isMenuCondensed: false,
    }
  },
  computed: {
    layout()  { return this.$store.state.layout },
  },
  methods: {
    toggleRightSidebar() {
      document.body.classList.toggle("right-bar-enabled");
    },
    hideRightSidebar() {
      document.body.classList.remove("right-bar-enabled");
    },
    toggleMenu() {
      this.isMenuCondensed = !this.isMenuCondensed
      if (this.isMenuCondensed)
        document.body.setAttribute('data-sidebar-size', 'condensed');
      else
        document.body.removeAttribute('data-sidebar-size', 'condensed');

      if (window.screen.width >= 992) {
        this.$router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove("sidebar-enable");
        });
      } else {
        document.body.setAttribute('data-sidebar-size', 'default');
        document.body.classList.toggle("sidebar-enable");
        this.$router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove("sidebar-enable");
        });
      }
    },
  },
  mounted() {
    // this.$store.dispatch('changeLeftSidebarSize', 'condensed');

    document.body.removeAttribute("data-layout-mode");
    document.body.removeAttribute("data-sidebar-showuser");
  }
}
</script>

<style scoped>
.main_content_wrapper{
    width: 75%;
    float:left
}
.ads_content_wrapper{
    width: 25%;
    padding-left: 20px;
    float:left
}
@media(max-width:768px){
    .main_content_wrapper{
        width: 100%;
    }
    .ads_content_wrapper{
        width: 100%;
    }
}
</style>

<style type="text/css">
.fade-enter-active, .fade-leave-active {
  transition: opacity .7s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

</style>
